import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    TableContainer,
    Table,
    Paper,
    Container,
    Divider
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { CustomTableProps } from '@propTypes/index';
import { getComparator, stableSortNonQuery } from './helpers'
import { SimpleSearch, CustomTableHead, CustomTableBody, CustomTableFooter, TableTools } from './components'
import { CustomTableStyle } from 'customStyles/CustomTableStyle';
import { useTableStore } from 'state/TableStore';
import { PageNames } from 'interfaces/config';
import { MenuState } from 'customEnums/MenuState';
import { extractPageName } from './helpers';
import { LogConfig } from "../../utils";
import { AdvancedSearchAction } from "customEnums/TransactionSummaryEnums";
import FilterListIcon from '@mui/icons-material/FilterList';
import { useUserStore } from 'state';

export const CustomTable: React.FC<CustomTableProps> = ({
    tableConfig,
    rows,
    hideSearch,
    openModalBlur,
    openAgencyEdit,
    tcns,
    totalRecords,
    setSearched,
    handleDeselectAll,
    setValue,
    handleSelectAll,
    setDisplaySavedMessage,
    setCreateSearch,
    setSearchLength,
    advancedSearchNumParams,
    isSelectedRow,
    currentPage,
    pageSize,
    loading,
    columnStateUpdate,
    handleHideAll,
    handleShowAll,
    handleReset,
    searched,
    order,
    orderBy,
    handleRequestSort,
    selected,
    setSelected,
    value,
    selectAllState,
    rowClickHandler,
    tableName,
    setOrderBy,
    dispatchFormValues
}) => {
    const columnState = useTableStore((state => state.columnState))
    const locationObj = useLocation()
    const pageName = extractPageName(locationObj.pathname);

    const applicantDetails = PageNames.APPLICANT_DETAILS === pageName

    const [menuState, setMenuState] = useState(MenuState.CATEGORY)
    const setResetTable = useTableStore(state => state.setResetTable)
    const setSearchParams = useTableStore((state) => state.setSearchParams)
    const setDisplayNoSearchResults = useTableStore(state => state.setDisplayNoSearchResults)
    const setSimpleSearchRan = useTableStore(state => state.setSimpleSearchRan)
    const pagination = useTableStore(state => state.pagination)

    const [datePlaceHolder, setDatePlaceholder] = React.useState(false)
    const [hoverLabel, setHoverLabel] = React.useState("")
    const [visibleRows, setVisibleRows] = useState([])
    const config = useUserStore(state => state.config)
    const { hardLimit } = config.transactionViewing
    const tableRef = useRef<any>(null);

    const tableBodyRef = useRef()

    const differentBgColor = [
        "rb-summary-audit-history",
        "rb-sub-audit-history",
        "reject-codes",
        "rapback-maintenance"
    ];

    const tableBgColor = differentBgColor.includes(tableName) ? "background.default" : 'common.black'

    const tablesToPad = [
        "transaction-summary",
        "rapback-maintenance",
        "rapback-validations",
        "rapback-prenotifications",
        "rapback-summary",
        "agency-management",
        "user-management",
        "audit-history"
    ];

    const { tableHeaderCells, useQueryPagination } = tableConfig[tableName]

    useEffect(() => {
        if (tableRef.current && pageName !== "applicant-details") {
            tableRef.current.scrollIntoView({ behavior: "smooth", block: "start" })
        }
    }, [pagination])

    const resetTable = useCallback(() => {
        console.log('debug resetTable callback setValue: ', setValue)
        dispatchFormValues?.({ type: AdvancedSearchAction.RESET, hardLimit })
        setSelected?.([])
        setValue?.([])
        setMenuState(MenuState.CATEGORY)
        setDatePlaceholder(false)
        handleDeselectAll?.()
        setSearched?.(false)
        setSimpleSearchRan(false)
        setSearchParams([])
        setDisplayNoSearchResults(false)
        setSearchLength?.(0)
        if (pageName === "transaction-summary") setOrderBy?.("transactionDate")
    }, [setValue, dispatchFormValues, handleDeselectAll, setSearched, setSimpleSearchRan, setSearchParams, setDisplayNoSearchResults, setSearchLength])

    useEffect(() => {
        setResetTable(resetTable)
    }, [])

    //update row display for userManagement here
    useEffect(() => {
        console.log('debug user management data CustomTable resort check: ', { rows, useQueryPagination, tableName, order, orderBy })
        if (rows && useQueryPagination || (!order && !orderBy)) {
            if ((currentPage && pageSize)) {
                setVisibleRows(rows)
            }
        }
        if (rows && !useQueryPagination && (order && orderBy)) {
            let visibleRows = stableSortNonQuery(rows, getComparator(order, orderBy, pageName), orderBy).slice(
                currentPage * pageSize,
                currentPage * pageSize + pageSize,
            )
            const debugTablePagination: LogConfig = {
                identifier: "debugTablePagination",
                pageName,
                thingToDebug: "CustomTableFooter onRowsPerPageChange visibleRows",
                paramsToLog: {
                    start: currentPage * pageSize,
                    end: currentPage * pageSize + pageSize,
                    currentPage,
                    pageSize,
                    rows,
                    visibleRows
                }
            }
            setVisibleRows(visibleRows);
        }
    }, [
        tableName,
        loading,
        rows,
        rows?.length,
        order,
        orderBy,
        currentPage,
        pageSize,
        isSelectedRow?.length,
        selected?.length,
        columnState,
        useQueryPagination
    ])

    useEffect(() => {
        if (isSelectedRow) {
            if (isSelectedRow.length > 0) {
                setSelected(isSelectedRow)
            }
        }
    }, [isSelectedRow?.length])

    /*useEffect(() => {
        const debugTablePagination: LogConfig = {
            identifier: "1010101010 debug updated config",
            pageName: pageName,
            thingToDebug: "CustomTable visibleRows",
            paramsToLog: {
                visibleRows: visibleRows,
                rows
            }
        }
        //logger(debugTablePagination)
    }, [visibleRows?.length]);*/

    console.log('visible rows CustomTable tableheaderCells ', { tableHeaderCells })

    return (
        <Paper sx={{ maxHeight: "calc(100% - 40px)", display: "flex", flexDirection: "column", width: '100%', backgroundColor: tableName !== "rapback-maintenance" && tableName && tableName !== "rapback-maintenance" ? 'common.black' : 'background.default' }} elevation={0}>
            {
                tableName &&
                <>
                    {hideSearch ? null :
                        <Container disableGutters maxWidth={false} sx={CustomTableStyle.tableTopStyle}>
                            <SimpleSearch
                                tableConfig={tableConfig}
                                useQueryPagination={useQueryPagination}
                                tableName={tableName}
                                menuState={menuState}
                                setMenuState={setMenuState}
                                searched={searched}
                                value={value}
                                setValue={setValue}
                                label={pageName}
                                advancedSearchNumParams={advancedSearchNumParams ? advancedSearchNumParams : 0}
                                setCreateSearch={setCreateSearch ? setCreateSearch : () => { }}
                                datePlaceHolder={datePlaceHolder}
                                setDatePlaceholder={setDatePlaceholder}
                                setDisplaySavedMessage={setDisplaySavedMessage ? setDisplaySavedMessage : () => { }}
                            />
                            {
                                config && (<TableTools tableName={tableName} resetTable={resetTable} />)
                            }
                        </Container>
                    }
                    {
                        !applicantDetails && (
                            <Divider />
                        )
                    }
                    <TableContainer sx={{ boxShadow: "none", borderRadius: 0 }} component={Paper} >
                        <Table ref={tableRef} sx={{
                            overFlowX: 'scroll',
                            width: "100%",
                            paddingLeft: tableName && !tablesToPad.includes(tableName) ? 0 : 3,
                            paddingBottom: 1,
                            paddingRight: tableName && !tablesToPad.includes(tableName) ? 0 : 3,
                            backgroundColor: tableBgColor, borderRadius: 0
                        }} stickyHeader aria-label={pageName}>
                            <CustomTableHead
                                tableConfig={tableConfig}
                                tableSortLabelIcon={<FilterListIcon onClick={(e) => { e.stopPropagation() }} />}
                                currentPage={currentPage}
                                selectAllState={selectAllState}
                                tableName={tableName}
                                hoverLabel={hoverLabel}
                                setHoverLabel={setHoverLabel}
                                columnStateUpdate={columnStateUpdate}
                                handleHideAll={handleHideAll}
                                handleShowAll={handleShowAll}
                                handleReset={handleReset}
                                label={pageName}
                                numSelected={selected?.length}
                                rowCount={visibleRows?.length}
                                order={order}
                                orderBy={orderBy}
                                handleInternalSelectAll={handleSelectAll}
                                onRequestSort={handleRequestSort}
                                visibleRows={visibleRows?.length ? visibleRows : []}
                                tableBgColor={tableBgColor}
                            />
                            <CustomTableBody
                                tableBodyRef={tableBodyRef}
                                tableConfig={tableConfig}
                                tableName={tableName}
                                rowClickHandler={rowClickHandler}
                                openModalBlur={openModalBlur}
                                openAgencyEdit={openAgencyEdit}
                                loading={loading}
                                visibleRows={visibleRows?.length ? visibleRows : []}
                                tableBgColor={tableBgColor}
                            />
                        </Table>
                    </TableContainer>
                    <Divider />
                    <CustomTableFooter tableBodyRef={tableBodyRef} useQueryPagination={useQueryPagination} tcns={tcns} totalRecords={totalRecords} tableName={tableName} />
                </>
            }
        </Paper>
    )
}