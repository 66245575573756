import { TableConfig } from "interfaces/config"
import { useEffect, useRef, useState } from "react"
import { useTableStore, useUserStore } from "state"
import { generateTableConfig } from "utils"

export const useSetConfig = (pageName: string, defaultPagination: { page: number, rowsPerPage: number }, tableName: string, optionalConfigItems?: any, actionsConfig?: any, buttonCellHandlers?: any) => {

    console.log(`debug useSetConfig setting actionsConfig: debug useSetConfig  hook top of hook params: `, {
        pageName,
        tableName,
        optionalConfigItems,
        actionsConfig,
        buttonCellHandlers,
        defaultPagination
    })

    const pagination = useTableStore(state => state.pagination)
    const setActionsConfig = useTableStore(state => state.setActionsConfig)
    const setButtonCellHandlers = useTableStore(state => state.setButtonCellHandlers)
    const tableConfig = useTableStore(state => state.tableConfig)
    const setTableConfig = useTableStore(state => state.setTableConfig)
    const columnState = useTableStore(state => state.columnState)
    const setColumnState = useTableStore(state => state.setColumnState)
    const config = useUserStore(state => state.config)
    const userConfig = useUserStore(state => state.userConfig)
    const setApplicantDetailFields = useTableStore(state => state.setApplicantDetailFields)
    const setApplicantHeader = useTableStore(state => state.setApplicantHeader)
    const { usersPermissions } = userConfig
    const [page, setPage] = useState(defaultPagination.page)
    const [rowsPerPage, setRowsPerPage] = useState(defaultPagination.rowsPerPage)
    const [tableHeaderCells, setTableHeaderCells] = useState()
    const runCount = useRef({[tableName]: 0 });



    useEffect(() => {
        try {
            if (actionsConfig) {
                console.log(`debug useSetConfig setting actionsConfig: `, actionsConfig)
                setActionsConfig(actionsConfig)
            }

            if (buttonCellHandlers) {
                console.log(`debug useSetConfig setting buttonCellHandlers: `, buttonCellHandlers)
                setButtonCellHandlers(buttonCellHandlers)
            }

            console.log(`debug useSetConfig columnState and tableConfig: `, { columnState, tableConfig })
            //refersh doesnt work bc tableState is persisted columnState isnt
            const generatedConfig: TableConfig = generateTableConfig(config, pageName, tableName, usersPermissions);
            console.log('debug rb sub reset ', generatedConfig, {columnState, tableConfig, pageName, tableName})

            setColumnState({
                ...columnState,
                page: {
                    ...columnState?.page,
                    [pageName]: {
                        ...columnState?.page[pageName],
                        [tableName]: {
                            columnVisibility: tableConfig[tableName] ? columnState.page[pageName][tableName].columnVisibility : generatedConfig[tableName]?.columnVisibility,
                            filters: tableConfig[tableName] ? columnState.page[pageName][tableName].filters : generatedConfig[tableName]?.filterList
                        }

                    }
                }
            })
            if (pageName === "applicant-details") { 
                console.log('debug applicant details fields label: ', config.pages[pageName]["applicantDetailsFields"])
                setApplicantDetailFields(config.pages[pageName]["applicantDetailsFields"])
                setApplicantHeader(config.pages[pageName]["applicantHeader"])
            }
            
            if (tableConfig[tableName]) {
                if (!tableConfig[tableName].tableHeaderCells) {
                    console.log(`debug useSetConfig generatedConfig: `, generatedConfig)
                    setTableConfig({ ...tableConfig, [tableName]: { ...generatedConfig[tableName], ...optionalConfigItems } })
                    console.log(`debug useSetConfig tableConfig set to: `, { ...tableConfig, [tableName]: { ...generatedConfig[tableName], ...optionalConfigItems } })
                    
                } else {
                    const { tableHeaderCells } = tableConfig[tableName]
                    console.log('debug useSetConfig tableHeaderCells in useEffect: ', { tableName, tableConfig, tableHeaderCells })
                    setTableHeaderCells(tableHeaderCells)
                }
            } else {
                setTableConfig({ ...tableConfig, [tableName]: { ...generatedConfig[tableName], ...optionalConfigItems } })
                console.log(`debug useSetConfig tableConfig set to: `, { ...tableConfig, [tableName]: { ...generatedConfig[tableName], ...optionalConfigItems } })
            }

            console.log('debug rb sub columnState.page[pageName][tableName]?.filters.length: ', columnState.page[pageName][tableName]?.filters)

            

            /*setColumnState({
                ...columnState,
                page: {
                    ...columnState?.page,
                    [pageName]: {
                        ...columnState?.page[pageName],
                        [tableName]: {
                            columnVisibility: tableConfig[tableName] ? columnState.page[pageName][tableName].columnVisibility : generatedConfig[tableName]?.columnVisibility,
                            filters: tableConfig[tableName] ? columnState.page[pageName][tableName].filters : generatedConfig[tableName]?.filterList
                        }

                    }
                }
            })*/

            runCount.current = {[tableName]: runCount.current[tableName] + 1}


        } catch (e) {
            console.log('Error setting Config: ', e)
            throw new Error(`Error setting Config: ${e}`)

        }
        console.log(`debug useSetConfig hook top of useEffect tableConfig: `, tableConfig)

    }, [tableConfig])

    useEffect(() => {
        console.log(`101010 debug pagination useSetConfig useEffect pagination: `, pagination["status-history"])
        if (pagination.hasOwnProperty(tableName)) {
            const { page, rowsPerPage } = pagination[tableName]
            setPage(page)
            setRowsPerPage(rowsPerPage)
        }
    }, [pagination[tableName], actionsConfig, buttonCellHandlers, optionalConfigItems, pageName, tableName, defaultPagination])

    return { tableHeaderCells, page, rowsPerPage }
}