import React, { useCallback, useEffect, useReducer, useState } from 'react'
import { Paper, Typography, Grid, Container, Button, Tab, Tabs } from '@mui/material'
import { pageStyles } from '../../customStyles/common/index';
import { ConfirmModal, CustomTable, CustomTableTitleAndAlert, CustomTabPanel, tabProps } from '../../common'
import { useExpanded } from '../../components/layouts/ProtectedLayout';
import { ColumnState, ComponentConfig, useTableStore } from '../../state/TableStore';
import { Order } from '../../customTypes/index';
import { useSetConfig, useUserManagement } from 'hooks';
import { useUserManagementStore } from './state'
import { Components } from '../../state/TableStore'
import { RightDrawer } from '../../common';
import { EditUserDrawerContent } from './components/EditUserDrawerContent'
import { editUserReducer, addNewUserReducer, AddUserAction } from './helpers';
import { AddNewUser } from './components/AddNewUser'
import { EditUserAction } from "./helpers"
import { useMUITheme } from '../../theme/ThemeProvider';
import { UserRow, UserStatus } from "interfaces/hooks";
import { getComparator } from "../../common/CustomTable/helpers";
import { Role } from "interfaces/config";
import { AlertColor } from '../../customEnums/AlertColor';
import { v4 as uuid } from 'uuid'
import { useConfigService } from 'hooks/useConfigService';
import { useUserStore } from 'state';
import { TableNames } from 'customEnums/TableNames';
import { UserGroupLabel, UserGroups } from 'customEnums/UserGroups';
import { filterStatus, getUserGroup } from 'utils';

export const UserManagement: React.FC = () => {
  const { expanded } = useExpanded()
  const [currentSecurityLevel, setCurrentSecurityLevel] = useState("")
  const [currentStatus, setCurrentStatus] = useState("")
  const searchParams = useTableStore((state) => state.searchParams)
  const { mode } = useMUITheme();
  const userConfig = useUserStore(state => state.userConfig)
  const { userGroup, userRole, usersPermissions } = userConfig;
  const STATE = process.env.REACT_APP_CONFIG_STATE!

  const {
    loading,
    mappedData,
    runCount,
    totalRecords,
    updateUserGroup,
    updateUserStatus,
    updateUser,
    createUser,
    resendTempPassword,
    resetPassword,
    listUserStatus
  } = useUserManagement(userGroup, currentStatus, currentSecurityLevel, searchParams)

  // Table Store
  const pagination = useTableStore((state) => state.pagination)
  const setPagination = useTableStore(state => state.setPagination)
  const currentCell = useTableStore(state => state.currentCell)
  const setCurrentCell = useTableStore(state => state.setCurrentCell)
  const setHeaderButtonConfig = useTableStore(state => state.setHeaderButtonConfig)
  const setCustomFilterAnchorEl = useTableStore(state => state.setCustomFilterAnchorEl)
  const openNotification = useTableStore((state) => state.openNotification)
  const setOpenNotification = useTableStore((state) => state.setOpenNotification)
  const setAlertTitle = useTableStore(state => state.setAlertTitle);
  const setAlertMessage = useTableStore(state => state.setAlertMessage);
  const displayNoSearchResults = useTableStore((state) => state.displayNoSearchResults)
  const setDisplayNoSearchResults = useTableStore(state => state.setDisplayNoSearchResults)
  const setSeverity = useTableStore((state) => state.setSeverity)
  const simpleSearchRan = useTableStore((state) => state.simpleSearchRan)
  const isPost = useTableStore((state) => state.isPost)
  const isDelete = useTableStore((state) => state.isDelete)
  const configBody = useTableStore((state) => state.configBody)
  const columnState: ColumnState = useTableStore(state => state.columnState)
  const tableConfig = useTableStore(state => state.tableConfig)
  const config = useUserStore(state => state.config);
  const groupConfig = config?.cognito?.userRoles;

  // useUserManagementStore
  const setConfirmModalTitle = useUserManagementStore((state) => state.setConfirmModalTitle)
  const setConfirmModalContent = useUserManagementStore((state) => state.setConfirmModalContent)
  const setCancelButtonText = useUserManagementStore((state) => state.setCancelButtonText)
  const setHandleCancelAction = useUserManagementStore((state) => state.setHandleCancelAction)
  const setConfirmButtonText = useUserManagementStore((state) => state.setConfirmButtonText)
  const setHandleConfirmAction = useUserManagementStore((state) => state.setHandleConfirmAction)
  const isActive = useUserManagementStore((state) => state.isActive)
  const setIsActive = useUserManagementStore((state) => state.setIsActive)
  const selectedRow = useUserManagementStore((state) => state.selectedRow);
  const setSelectedRow = useUserManagementStore((state) => state.setSelectedRow)
  const openConfirmModal = useUserManagementStore(state => state.openConfirmModal)
  const setOpenConfirmModal = useUserManagementStore(state => state.setOpenConfirmModal)
  const confirmModalTitle = useUserManagementStore(state => state.confirmModalTitle)
  const confirmModalContent = useUserManagementStore(state => state.confirmModalContent)
  const cancelButtonText = useUserManagementStore(state => state.cancelButtonText)
  const confirmButtonText = useUserManagementStore(state => state.confirmButtonText)
  const handleConfirmAction = useUserManagementStore(state => state.handleConfirmAction)
  const selectedUserStatus = useUserManagementStore(state => state.selectedUserStatus)
  const setSelectedUserStatus = useUserManagementStore(state => state.setSelectedUserStatus)
  const handleCancelAction = useUserManagementStore(state => state.handleCancelAction)
  const setAddNewUserLoading = useUserManagementStore(state => state.setAddNewUserLoading)
  const setRemovedOriRfp = useUserManagementStore(state => state.setRemovedOriRfp)
  const removedOriRfp = useUserManagementStore(state => state.removedOriRfp)
  const setDefaultOrderBy = useTableStore(state => state.setDefaultOrderBy)

  const [sortOrder, setSortOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>('fullName');
  const [searched, setSearched] = useState<boolean>(false); //Set true when an advanced form multi parameter search has run
  const [value, setValue] = React.useState<any>([]);
  const [status, setStatus] = React.useState<string>("");
  const [groupName, setGroupName] = useState<string>("")
  const [openAddNewUser, setOpenAddNewUser] = useState<boolean>(false)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [data, setData] = useState<any>();
  const [confirmedUser, setConfirmedUser] = useState(true);
  const pageName = "user-management"
  const [tabValue, setTabValue] = React.useState(0);

  const { rfpListData } = useConfigService("rfpList.json", configBody, true, isPost, isDelete);
  
  const handleRequestSort = useCallback((event: any, property) => {
    const isAsc = orderBy === property && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property);
  }, [orderBy, sortOrder]);

  const getOriRfpGroupLiteral = (oriRfpGroup: object[], rfpListData: any) => {
    if(Object.keys(rfpListData).length > 0) {
      return oriRfpGroup.map(group => {
        const rfpValue = group["rfp"].value;
        const matchingEntry = JSON.parse(rfpListData).find(entry => entry.code === rfpValue);
        if (matchingEntry) {
          return {
            ...group,
            rfp: { ...group["rfp"], value: matchingEntry.rfp }
          };
        }
        return group;
      });
    } return oriRfpGroup
    
  }

  const getRfpLiteral = (code: string, rfpListData: any) => {
    if(Object.keys(rfpListData).length > 0) {
      const matchingEntry = JSON.parse(rfpListData).find(entry => entry.code === code.trim());
      if (matchingEntry) {
        return matchingEntry.rfp
      }
      return code
    }
  }

  const { tableHeaderCells, page, rowsPerPage } = useSetConfig(pageName, { page: 0, rowsPerPage: 25 }, TableNames.USER_MANAGEMENT, {})

  useEffect(() => {
    setDefaultOrderBy("fullName")
    setPagination({ ...pagination, [TableNames.USER_MANAGEMENT]: { page: 0, rowsPerPage: 25 } })
  }, [])

  useEffect(() => {
    const { sortOrder } = pagination[TableNames.USER_MANAGEMENT]
    console.log('debug reset sort order: ', pagination)
    if(sortOrder) setSortOrder(sortOrder)

  }, [pagination[TableNames.USER_MANAGEMENT]])

  useEffect(() => {
    setData(
      mappedData?.map((data: any) => {
        let userInfo: any = {
          key: data?.key,
          fullName: data?.fullName,
          loginName: data?.loginName,
          emailAddress: data?.emailAddress,
          primaryOri: data?.primaryOri?.filter((ori: string, index: any) => data.primaryOri.indexOf(ori) === index)?.join(' ')?.trim(),
          secondaryOri: data?.secondaryOri?.filter((sori: string, index: any) => data.secondaryOri.indexOf(sori) === index)?.join(' ')?.trim(),
          reasonFingerprinted: data?.reasonFingerprinted?.join(' ')?.trim(),
          lastModified: data?.lastModified,
          securityLevel: data?.securityLevel,
          oriRfpGroups: getOriRfpGroupLiteral(data?.oriRfpGroups, rfpListData),
          status: data?.status,
          loginStatus: data?.loginStatus,
          user: data?.user
        }
        return userInfo
      })
    )
  }, [mappedData, mappedData?.length, page, rowsPerPage])

  console.log('data123123', mappedData); 

  useEffect(() => {
    console.log('debug stuck search results useEffect: ', { runCount, searchParams, mappedData, simpleSearchRan, searched, setDisplayNoSearchResults: mappedData?.length === 0 && simpleSearchRan || mappedData?.length === 0 && searched })
    console.log('debug stuck search results useEffect: ', { conditional: runCount < 1 && searchParams.length > 0, negativeConditional: !(runCount < 1 && searchParams.length > 0) })
    if (runCount < 1 && searchParams.length > 0) {
      setDisplayNoSearchResults(false)
      setOpenNotification(false)
      return
    }
    if (mappedData?.length === 0 && simpleSearchRan || mappedData?.length === 0 && searched) {
      setDisplayNoSearchResults(true)
      setOpenNotification(true)
    }
    console.log('debug open notification', openNotification)

  }, [mappedData?.length, simpleSearchRan, searched])

  useEffect(() => {
    setTimeout(() => {
      if (displayNoSearchResults) {
        setDisplayNoSearchResults(false)
      }
      setOpenNotification(false)
      setSeverity("")
      setAlertTitle("")
      setAlertMessage('');
    }, 10000)
  }, [displayNoSearchResults, openNotification])

  const [formValues, setFormValues] = useState<any>({})

  // TODO: improve this reducer so it doesnt filter by env
  const [formState, dispatchFormValues] = useReducer(addNewUserReducer, {
    firstName: '', oriRfpGroups: [
      {
        primaryOri: { field: "Primary ORI", value: "" },
        rfp: { field: "RFP", value: "", required: true }
      },
      STATE !== "ga" && STATE !== "nm" && {
        secondaryOri: { field: "Secondary ORI", value: "" },
        rfp: { field: "RFP", value: "" }
      }
    ].filter(Boolean)
  })

  //Add trackable index to oriRfpGroups bc deleting one changes map index of oriRfpGroups. 
  // TODO: improve this reducer so it doesnt filter by env
  const [editUserFormState, editUserDispatch] = useReducer(editUserReducer, {
    firstName: '',
    oriRfpGroups: [
      {
        primaryOri: { field: "Primary ORI", value: "" },
        rfp: { field: "RFP", value: "", required: true }
      },
      STATE !== "ga" && STATE !== "nm" && {
        secondaryOri: { field: "Secondary ORI", value: "" },
        rfp: { field: "RFP", value: "" }
      }
    ].filter(Boolean)
  })

  //Keep for when we get a count back from user management api
  /*const paginationTokenMap = [{token: "", page }]

  useEffect(() => {
    paginationTokenMap.push({token: paginationToken, page})
  }, [paginationToken, page])

  const fetchNextPage = useCallback(() => {
    console.log(`debug sort 001010100101 fetchNextPage called with token ${paginationToken}`)
    if(paginationToken) fetchUsers(paginationToken)
  }, [paginationToken])

  setHandleCustomRefetch(fetchNextPage)*/

  const closeAddNewUser = () => {
    setOpenAddNewUser(false)
    setAddNewUserLoading(false)
    dispatchFormValues({ type: AddUserAction.RESET })
  }

  const mapNewUserInfo = (user: any) => {
    const formattedDate = Date().slice(4, 15);
    let userInfo: any = {
      key: uuid(),
      fullName: `${user.firstName} ${user.lastName}`,
      loginName: user.userName,
      emailAddress: user.email,
      primaryOri: user?.oriRfpGroups[0]?.primaryOri?.value,
      secondaryOri: user?.oriRfpGroups[1]?.secondaryOri?.value,
      reasonFingerprinted: getRfpLiteral(user?.oriRfpGroups[0]?.rfp?.value, rfpListData),
      lastModified: formattedDate,
      securityLevel: getUserGroup(user?.securityLevel, groupConfig),
      status: UserStatus.ACTIVE,
      oriRfpGroups: user.oriRfpGroups,
    };
    return userInfo;
  };

  const handleCreateUser = useCallback(async (e) => {
    setAddNewUserLoading(true)
    const { firstName, lastName, userName, email, oriRfpGroups, securityLevel } = formState
    const mappedNewUserData = mapNewUserInfo(formState)
    const createUserResp = await createUser(userName, email, lastName, firstName, getOriRfpGroupLiteral(oriRfpGroups, rfpListData), filterStatus(mappedNewUserData?.status), securityLevel);
    if (createUserResp && createUserResp.code === 200) {
      setData([mappedNewUserData, ...data])
      setAlertTitle('New User Created')
      setAlertMessage(`${userName} was successfully created.`)
      setSeverity(AlertColor.SUCCESS)
      setOpenNotification(true)
    } else {
      setOpenNotification(true);
      setSeverity(AlertColor.ERROR)
      setAlertTitle(`New User Creation Failed`)
      setAlertMessage(createUserResp.message ?? `Please try again in a few minutes.`)
    }
    closeAddNewUser()
  }, [formState])

  useEffect(() => {
    const headerButtonConfig = {
      handler: () => setOpenAddNewUser(true),
      headerButtonIcon: null,
      buttonLabel: "",
      disabled: !usersPermissions?.viewUserManagement,
      text: "ADD NEW USER"
    }
    setHeaderButtonConfig(headerButtonConfig)
  })

  // User security Update Submission
  const handleSecurityUpdateConfirm = useCallback(async () => {
    setCurrentCell("")
    setOpenConfirmModal(false);
    const user = (selectedRow as UserRow)?.user
    if (groupName && user) {
      const res = await updateUserGroup(groupName, user)
      if (res?.code === 200) {
        setAlertTitle('Security Level Updated')
        setAlertMessage(`${currentCell} security level was successfully updated.`)
        setSeverity(AlertColor.SUCCESS)
        setOpenNotification(true)
        setSeverity(AlertColor.SUCCESS)
        setData((prevData: any) => {
          return prevData.map((user: any) => {
            if (user.loginName === currentCell) {
              return { ...user, ...selectedRow }
            }
            return user
          })
        })
      } else {
        setOpenNotification(true);
        setSeverity(AlertColor.ERROR)
        setAlertTitle(`Account Update Failed`)
        setAlertMessage(res.message ?? `Please try again in a few minutes.`)
      }
    }
  }, [groupName, currentCell])

  // User status Update Submission
  const handleStatusUpdateConfirm = useCallback(async () => {
    setCurrentCell("")
    setOpenConfirmModal(false)
    const user = (selectedRow as UserRow)?.user
    if (currentCell && user) {
      const res = await updateUserStatus(isActive, user)
      if (res.code === 200) {
        setAlertTitle(`Account ${status === "inactive" ? "Deactivated" : "Activated"}`)
        setAlertMessage(`${currentCell}'s account was successfully ${status === "inactive" ? "deactivated" : "activated"}.`)
        setSeverity(AlertColor.SUCCESS)
        setOpenNotification(true)
        setSeverity(AlertColor.SUCCESS)
        setData((prevData: any) => {
          return prevData.map((user: any) => {
            if (user.loginName === currentCell) {
              return { ...user, ...selectedRow }
            }
            return user
          })
        })
      } else {
        setOpenNotification(true);
        setSeverity(AlertColor.ERROR)
        setAlertTitle(`Account Update Failed`)
        setAlertMessage(res.message ?? `Please try again in a few minutes.`)
      }
    }
  }, [isActive, currentCell, status])

  const setComponent = useTableStore((state) => state.setComponent)
  const component = useTableStore((state) => state.componentConfig)
  const currentDataIndex = useTableStore(state => state.currentDataIndex)

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>, currentDataIndex) => {
    var currentDate = Date().slice(4, 15)
    event.stopPropagation();
    setCustomFilterAnchorEl(null)
    setComponent(
      {
        ...component,
        [Components.CUSTOM_FILTER]: {
          ...component[Components.CUSTOM_FILTER],
          selectedValue: event.target.value
        }
      }
    )
    let selectedValue = event.target.value
    let selectedLabel
    switch (selectedValue) {
      case 'active':
        setIsActive(true);
        selectedLabel = "Active"
        break;
      case UserGroups.MC_ADMIN:
        selectedLabel = UserGroupLabel.MC_ADMIN_LABEL;
        break;
      case UserGroups.MC_STATE_POLICE_ADMIN:
        selectedLabel = UserGroupLabel.MC_STATE_POLICE_ADMIN_LABEL;
        break;
      case UserGroups.MC_STATE_POLICE_USER:
        selectedLabel = UserGroupLabel.MC_STATE_POLICE_USER_LABEL;
        break;
      case UserGroups.MC_EDIT:
        selectedLabel = UserGroupLabel.MC_EDIT_LABEL;
        break;
      case UserGroups.MC_READONLY:
        selectedLabel = UserGroupLabel.MC_READONLY_LABEL;
        break;
      case UserGroups.MC_READONLY_RESTRICTED_VIEW:
        selectedLabel = UserGroupLabel.MC_READONLY_RESTRICTED_VIEW_LABEL;
        break;
      case UserGroups.MC_AGENCY_ADMIN:
        selectedLabel = UserGroupLabel.MC_AGENCY_ADMIN_LABEL;
        break;
      case UserGroups.MC_AGENCY_USER:
        selectedLabel =  UserGroupLabel.MC_AGENCY_USER_LABEL;
        break;
      case 'inactive':
        setIsActive(false);
        selectedLabel = "Inactive"
        break
      default:
        selectedLabel = Role[selectedValue]; // If selected value not found in the options, use the selected value itself as the label
    }

    setSelectedRow({
      ...selectedRow,
      [currentDataIndex]: selectedLabel,
      "lastModified": currentDate
    })

    switch (currentDataIndex) {
      case "securityLevel":
        setOpenConfirmModal(true)
        setGroupName(selectedValue)
        setConfirmModalTitle("UPDATE SECURITY LEVEL?")
        setConfirmModalContent(
          <div>
            You have chosen to update <b>{currentCell}</b> to security level <b>{selectedLabel}.</b> Would you like to proceed?
          </div>)
        setCancelButtonText("CANCEL")
        setConfirmButtonText("UPDATE")
        break;
      case "status":
        setHandleConfirmAction(handleStatusUpdateConfirm)
        setOpenConfirmModal(true)
        setConfirmModalTitle("UPDATE STATUS")
        setStatus(selectedValue)
        setConfirmModalContent(
          <div>
            You have chosen to <b>{selectedValue === "inactive" ? "deactivate" : "activate"} {currentCell}</b> user account. Would you like to proceed?
          </div>
        )
        setCancelButtonText("CANCEL")
        setConfirmButtonText("CONTINUE")
        break;
    }
  };

  const componentConfig: ComponentConfig = {
    "CustomFilter": {
      ...component["CustomFilter"],
      handleRadioChange: handleRadioChange
    }
  }

  useEffect(() => {
    setComponent(componentConfig)
    if (groupName) {
      setHandleConfirmAction(handleSecurityUpdateConfirm)
    }
    if (currentDataIndex === "status") {
      setHandleConfirmAction(handleStatusUpdateConfirm)
    }
  }, [currentCell, currentDataIndex, groupName, status])

  useEffect(() => {
    setTimeout(() => {
      setOpenNotification(false);
      setDisplayNoSearchResults(false);
      setSeverity("");
    }, 5000)
  }, [openNotification])

  const closeConfirmModal = () => {
    setOpenConfirmModal(false)
  }

  useEffect(() => {
    if (openAddNewUser) {
      setHandleConfirmAction(handleCreateUser)
      setConfirmModalTitle("ADD NEW USER")
      setConfirmModalContent(`
      Edit the information below and select 'Add User'. Select 'Cancel' to exit. New users will receive a temporary password, and are required to set up 
      both a new password and multi-factor authentication during their first login.
      `)
      setCancelButtonText("CANCEL")
      setConfirmButtonText("ADD USER")
      setHandleCancelAction(closeAddNewUser)
      setIsActive(!isActive);
      setStatus("active")
    } else {
      setHandleCancelAction(closeConfirmModal)
    }
  }, [openAddNewUser, formState, openConfirmModal])


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleEditUser = useCallback(async (e, row: UserRow) => {
    setOpenDrawer(true)
    editUserDispatch({ type: EditUserAction.SET_CURRENT, value: row })
    setCurrentStatus(row.status!)
    setCurrentSecurityLevel(row.securityLevel!)
    setCurrentCell(row.loginName);
    setSelectedUserStatus(row.loginStatus!);
    if (row?.loginName) {
      const userStatus = await listUserStatus(row.loginName);
      const isConfirmed = userStatus?.isConfirmed ?? true;
      setConfirmedUser(isConfirmed)
    }
  }, [])

  const handleUpdateUser = useCallback(async (e) => {
    setRemovedOriRfp([])
    console.log('debug update user security level from drawer: ', editUserFormState)
    const updateUserResp = await updateUser(editUserFormState)
    setOpenDrawer(false)
    const updateRfp = getOriRfpGroupLiteral(editUserFormState.oriRfpGroups, rfpListData)
    const fullName = editUserFormState.firstName + " " + editUserFormState.lastName

    if (updateUserResp.code === 200) {
      setAlertTitle('User Updated')
      setSeverity(AlertColor.SUCCESS)
      setAlertMessage(`${fullName} was successfully updated.`)
      setOpenNotification(true)
      setSeverity(AlertColor.SUCCESS)
      setData((prevData: any) => {
        let updatedUserRow = {
          fullName: editUserFormState.firstName + " " + editUserFormState.lastName,
          emailAddress: editUserFormState.email,
          securityLevel: editUserFormState.securityLevel,
          status: editUserFormState.status,
          loginName: editUserFormState.userName,
          primaryOri: editUserFormState?.oriRfpGroups[0]?.primaryOri?.value,
          oriRfpGroups: editUserFormState?.oriRfpGroups,
          secondaryOri: editUserFormState?.oriRfpGroups[1]?.secondaryOri?.value,
          reasonFingerprinted: updateRfp?.[0]["rfp"].value,
          user: editUserFormState.user
        }
        return prevData.map((user: any) => {
          if (user.loginName === currentCell) {
            return { ...user, ...updatedUserRow }
          }
          return user
        })
      })

    } else {
      setOpenNotification(true);
      setSeverity(AlertColor.ERROR)
      setAlertTitle(`Account Update Failed`)
      setAlertMessage(updateUserResp.message ?? `Please try again in a few minutes.`)
      setSeverity(AlertColor.ERROR)
    }
  }, [editUserFormState, currentCell])

  const handleCancel = useCallback(() => {
    console.log('debug manage oriRfpGroups close drawer button pressed: ', {removedOriRfp})
    setOpenDrawer(false)
    editUserDispatch({ type: EditUserAction.REMOVE_EMPTY_ORI_RFP_GROUP })
    if(removedOriRfp.length > 0) {
      editUserDispatch({ type: EditUserAction.RESET_ORI_RFP_GROUP, removedOriRfp})
      setRemovedOriRfp([])
    }
  }, [editUserFormState?.oriRfpGroups, removedOriRfp])

  const handleResendLoginEmail = useCallback(async () => {
    // Will only work when the user's status is FORECE_CHANGE_PASSWORD.
    // Will send a new welcome email with a new password with a new password + expiration date refreshed.
    setOpenDrawer(false)
    if (currentCell) {
      const resp = await resendTempPassword(currentCell)
      if (resp.code === 200) {
        setAlertTitle(`Login Email Re-sent`)
        setAlertMessage(`${currentCell} will receive an instructional email shortly.`)
        setOpenNotification(true)
        setSeverity(AlertColor.SUCCESS)
      } else {
        setOpenNotification(true);
        setSeverity(AlertColor.ERROR)
        setAlertTitle(`Sending Login Email Failed`)
        setSeverity(AlertColor.ERROR)
        setAlertMessage(`Please try again in a few minutes.`)
      }
    } else {
      setOpenNotification(true)
      setSeverity(AlertColor.ERROR)
      setAlertTitle(`Unable to Resend Login Email for ${currentCell}`)
      setAlertMessage(`Please try Resetting ${currentCell}'s Password Instead.`)
    }

  }, [currentCell])

  const handleResetUserMFA = useCallback(async () => {
    // Reset User MFA API
    setOpenDrawer(false)
    if (currentCell) {
      const resp = await resetPassword(currentCell)
      if (resp.code === 200) {
        setAlertTitle(`User's MFA Successfully Reset`)
        setAlertMessage(`${currentCell} may attempt to login again`)
        setOpenNotification(true)
        setSeverity(AlertColor.SUCCESS)
      } else {
        setOpenNotification(true);
        setSeverity(AlertColor.ERROR)
        setAlertTitle(`Sending Login Email Failed`)
        setAlertMessage(`Please try again in a few minutes.`)
      }
    }
  }, [currentCell])

  return (
    <Paper elevation={0} sx={
      {...pageStyles.pagePaperStyle,
        minWidth: `calc(100vw - ${expanded ? "256px" : "64px"})`
      }}>
      <CustomTableTitleAndAlert name="User Management" />
      <RightDrawer containsTable={false} openDrawer={openDrawer} closeDrawer={handleCancel}>
      <Typography sx={{ marginBottom: "10px" }} variant="h5">Manage User</Typography>

        <Container sx={{ marginBottom: "24px", mt: '24px' }} disableGutters>
          <Tabs sx={{ "..MuiTabs-root": { height: "42px" } }} value={tabValue} onChange={handleChange} aria-label=" tabs">
            <Tab label="Information" {...tabProps(0)} /> 
            <Tab label="Login and Password" {...tabProps(1)} />
          </Tabs>
        </Container>
        <Container disableGutters sx={{ flex: 2, gap: '2rem' }}>
          <CustomTabPanel value={tabValue} index={0}>
            <EditUserDrawerContent
              data={data?.find(user => user.loginName === editUserFormState.userName)}
              formState={[editUserFormState, editUserDispatch]}
              handleCancel={() => handleCancel()}
              closeDrawer={() => setOpenDrawer(false)}
              handleUpdate={handleUpdateUser}
              loading={loading}
              rfpListData={rfpListData}
            />
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            <Grid sx={{ pl: '1rem', mb: "24px" }}>
              <Typography variant='subtitle2' sx={{ mb: '0.5rem', fontSize: '16px' }}>Password and Login Management</Typography>
              <Typography variant='body1'>Ensure any updates to the user's current email
                address and/or uername are made and saved before resetting their password
                or resending the email.
              </Typography>
            </Grid>
            <Container disableGutters sx={{ display: "flex", flexDirection: "row", pl: '1rem', gap: '1rem' }}>
              <Button variant='outlined' color='primary' sx={{ color: mode === 'dark' ? 'text.primary' : '#430099' }} onClick={() => handleResetUserMFA()}>
                RESET USER MFA
              </Button>
              <Button variant='outlined' color='primary' sx={{ color: mode === 'dark' ? 'text.primary' : '#430099' }} onClick={() => handleResendLoginEmail()} disabled={confirmedUser}>
                RE-SEND LOGIN EMAIL
              </Button>
            </Container>
          </CustomTabPanel>
        </Container>
      </RightDrawer>
      {
        tableHeaderCells && (
          <CustomTable
            //Required props
            tableConfig={tableConfig}
            tableName="user-management"
            loading={loading}
            rows={data?.length ? data : []}
            columnState={columnState}
            orderBy={orderBy}
            order={sortOrder}
            totalRecords={totalRecords} //update once we have actual count based on parameters
            currentPage={page}
            pageSize={rowsPerPage}
            searched={searched}
            value={value}
            handleRequestSort={handleRequestSort}
            setValue={setValue}
            setSearched={setSearched}
            rowClickHandler={handleEditUser} />
        )
      }

      <ConfirmModal
        customWidth={openAddNewUser ? "1150px" : '600px'}
        customHeight={openAddNewUser ? "600px" : '193px'}
        title={confirmModalTitle}
        content={confirmModalContent}
        cancelButtonText={cancelButtonText}
        confirmButtonText={confirmButtonText}
        handleCancel={handleCancelAction}
        handleConfirm={handleConfirmAction}
        openDialog={openConfirmModal || openAddNewUser}
        openAddNewUser={openAddNewUser}
      >
        {
          openAddNewUser && rfpListData && (
            <AddNewUser
              formValues={formValues}
              formState={[formState, dispatchFormValues]}
              setFormValues={setFormValues}
              rfpListData={rfpListData}
            />
          )
        }
      </ConfirmModal>
    </Paper>
  )
}
